import React, { useEffect, useState } from "react";
// import { app, auth } from "./firebase";
// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import "firebase/compat/messaging";
import { getMessaging, getToken } from "firebase/messaging";
import "firebase/compat/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB8YeifobKfJJSO3nselAILteDgp4iIAMA",
    authDomain: "loginwithphone-9fb64.firebaseapp.com",
    projectId: "loginwithphone-9fb64",
    storageBucket: "loginwithphone-9fb64.appspot.com",
    messagingSenderId: "395072549821",
    appId: "1:395072549821:web:4f59770be42fda341a98d4",
    measurementId: "G-RWZEXW6VMP"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const analytics = firebase.analytics()
// analytics.logEvent('add_to_cart')
export const messaging = firebase.messaging()

export const generateToken = async () => {
    await Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            const token = messaging.getToken({ vapidKey: 'BHxb8L5RKKlyVfHRdCBznzfa7Z7pxB-mPXbaUDnxaLFovUxZZuHyEpa3bni3ALXlfAzvjYwQU0Bi9g6Kl1TB7QU' })
            // console.log(token)
        }
    })
}


// messaging.onBackgroundMessage((payload) => {
//     console.log('[firebase-messaging-sw.js] Received background message ', payload)
//     const notificationTitle = payload.notification.title
//     const notificationOptions = {
//         body: payload.notification.body,
//         icon: payload.notification.icon || payload.notification.image,
//     }

//     self.registration.showNotification(notificationTitle, notificationOptions)
// })