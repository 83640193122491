import React from 'react'
import {
    useNavigate,
    useLocation
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from "react";
import { oncheckout } from './../Redux/action';
import axios from 'axios';

const CheckoutForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { state } = useLocation();
    console.log(state);

    const [LISTOFORDERED, setLISTOFORDERED] = useState([])

    let ListOfordered = []

    ListOfordered = useSelector(state => state.allProducts.listoforder)

    // console.log(ListOfordered)
    useEffect(() => {
        dispatch(oncheckout())

    }, [])
    if (!state) {
        return <p>Sorry Dude !</p>
    }

    const updateDeliveredHistory = (data, action, update) => {

        axios.post("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/helth", {
            "product_id": 1,
            "order_id": data.order_id,
            "section": data.section,
            "email": data.email,
            "phone": data.phone,
            "delivery_name": data.delivery_name,
            "billing_address": data.billing_address,
            "street_address": data.street_address,
            "state": data.state,
            "city": data.city,
            "pin_code": data.pin_code,
            "payment_success": data.payment_success,
            // "payment_success": "Success",
            "payment_location": "en",
            "approvedby_admin": "Approved",
            "delivered": action == 'delevered' ? update : data.delivered,
            "received": action == 'received' ? update : data.received,
            "date": data.date,
            // "date": "19/12/2023 01:49:04",
            "total": data.total,
            "product_for": data.product_for,
            "tracking_id": data.tracking_id
            // "tracking_id": "152865758552"
        }).then(r => r).catch(e => e)
    }



    return (<div className="p-10">
        <ul role="list" className=" divide-y divide-gray-100">
            {ListOfordered && ListOfordered.map((person) => (
                <li key={person.tracking_id} className="flex justify-between gap-x-6 py-5" >
                    <div className="flex min-w-0 gap-x-4">
                        {/* <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={person.imageUrl} alt="" /> */}
                        <h3>order Id :{person.order_id}</h3>
                        <div className="flex">
                            <h3>Order by:</h3>  <p className="text-sm font-semibold leading-6 text-gray-900">{person.product_for}</p>
                        </div>
                        <h3>Pincode:</h3> <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.pin_code}</p>
                        <h3>Payment:</h3> {person.payment_success !== "Success" ? <p className="text-sm font-semibold leading-6 text-red-500">{person.payment_success}</p> : <p className="text-sm font-semibold leading-6 text-green-500">{person.payment_success}</p>}
                        {person.approvedby_admin == "Approved" ? <p className="mt-1 truncate text-xs leading-5 text-green-500">{person.approvedby_admin}</p> : <p className="mt-1 truncate text-xs leading-5 text-red-500">Not Approved</p>}

                        {person.delivered == false && person.payment_success == "Success" ? <span
                            className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"
                            onClick={() => updateDeliveredHistory(person, "delevered", true)}>Delever
                        </span> : <p className="text-sm font-semibold leading-6 text-green-500" onClick={() => updateDeliveredHistory(person, "delevered", true)}>{person.payment_success}</p>}

                        {person.delivered == true && person.received == true ? <p className="text-sm font-semibold leading-6 text-green-500">{person.received}</p> : <span
                            className="inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"
                            onClick={() => updateDeliveredHistory(person, "received", true)}>Finish</span>}

                        {person.payment_success == "Success" ? <div onClick={() => navigate("/detailtocheckout", { state: person })}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            </svg>
                        </div> : ""}
                    </div>
                </li>
            ))}
            <hr></hr>
        </ul>
    </div>
    )
}

export default CheckoutForm;