import React, { Fragment, useState } from 'react'
import { Dialog, RadioGroup, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { StarIcon } from '@heroicons/react/20/solid'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function WatchTvDialog(props) {
    const [open, setOpen] = useState(props.open)
    if (!props.watchurl) {
        return (
            <div class="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
                <div class="animate-pulse flex space-x-4">
                    <div class="rounded-md bg-slate-200 h-10 w-10"></div>
                    <div class="flex-1 space-y-6 py-1">
                        <div class="h-2 bg-slate-200 rounded"></div>
                        <div class="space-y-3">
                            <div class="grid grid-cols-3 gap-4">
                                <div class="h-2 bg-slate-200 rounded col-span-2"></div>
                                <div class="h-2 bg-slate-200 rounded col-span-1"></div>
                            </div>
                            <div class="h-2 bg-slate-200 rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    // console.log(props.cardProduct, selectedSize)
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="absolute z-10 lg:z-40 h-screen w-screen" onClose={props && props.onOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="modal-overlay absolute h-screen w-screen bg-white opacity-95" />
                </Transition.Child>

                <div className="fixed z-40 inset-0 z-10 overflow-y-auto">
                    <div className="flex w-screen h-screen items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <div>
                                {/* <Dialog.Panel className="flex h-screen w-screen transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl"> */}
                                {/* <div className="relative flex h-screen w-screen items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8"> */}
                                <button
                                    // href="#"
                                    type="button"
                                    className="absolute right-4 top-9 z-10 rounded bg-red-700 text-white-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                                    // onClick={() => setOpen(!open)}
                                    onClick={() => props.setDialog(!open)}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                                <iframe src={props.watchurl} title="watchtv" style={{ width: "100%", height: "100%", position: "fixed", top: 0, left: 0, bottom: 0, right: 0 }}></iframe>

                                {/* </div> */}
                                {/* </Dialog.Panel> */}
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}