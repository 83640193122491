import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Logo from './../assist/triokzzlohgo.svg';
import { Helmet } from 'react-helmet-async';

const Login = () => {
    const navigate = useNavigate()
    const userstate = useLocation()
    const [stateofcountry, setstateofcountry] = useState("")
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    // let session = JSON.parse(sessionStorage.getItem('PHONEOTPINFO'))
    // console.log("session", session)
    // console.log(userstate.jti.split("").splice(0,5).join("")+userstate.exp )
    // console.log( userstate && userstate.state && userstate.state.user && userstate.state.user.multiFactor && userstate.state.user.multiFactor.user && userstate.state.user.multiFactor.user.phoneNumber && userstate.state.user.multiFactor.user.phoneNumber.slice(3, 13))
    let ListOfOrdered = useSelector(state => state.allProducts.order)

    let subtotal = ListOfOrdered && ListOfOrdered.reduce((acc, curr) => {
        let itemtotal = curr.price * (curr.count ? curr.count : 1)
        return acc += itemtotal
    }, 0)
    let pincode = watch('delivery_zip')
    useEffect(() => {

        // console.log('billing-zip', pincode)
        if (pincode) {
            setTimeout(() => {
                axios.get(`https://api.postalpincode.in/pincode/${parseInt(pincode)}`)
                    .then(r => setstateofcountry(r.data))
            }, 2000)

        }


    }, [pincode])

    const onSubmit = (data, e) => {


        // 5 m timeout:

        // const timeoutId = setTimeout(() => controller.abort(), 10000)
        // setTimeout(() => {
        axios.post("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/products", {
            "product_id": 1,
            "order_id": data.order_id,
            "section": [],
            "email": data.billing_email,
            "phone": data.delivery_tel,
            "delivery_name": data.delivery_name,
            "billing_address": data.delivery_address,
            "street_address": data.billing_address,
            "state": data.delivery_state,
            "city": data.delivery_city,
            "pin_code": data.delivery_zip,
            // "payment_success": result.order_status,
            "payment_success": "Inprogress",
            "payment_location": "en",
            "approvedby_admin": "Approved",
            "delivered": false,
            "received": false,
            // "date": result.trans_date,
            // "date": "19/12/2023 01:49:04",
            "total": data.amount,
            "product_for": data.billing_name,
            // "tracking_id": result.tracking_id
            // "tracking_id": "152865758552"
        }).then(r => {
            alert("we will contact you soon, Thank You")
        }).catch(e => alert("we will contact you soon, Thank You"))
        // }, ((5 * 60) * 1000))
    }
    return (<div>
        <Helmet>
            <title>Save Soil- Triokzz Campaign For Pune.</title>
            <meta name="description"
                content="Namaskaram, Welcome to plant a tree campaign. Plant a tree so next generation can get air for free.">
            </meta>
            <meta name="robots" content="index, follow" />
            <meta name="robots" content="max-image-preview:large" />
            <link rel="canonical" href="https://www.triokzz.com/savesoil" />
            <meta name="keywords" content="Save soil, plant tree, mango tree event"></meta>
            <meta name="author" content="kunal khadke" />
            <meta name="application-name" content="Triokzz"></meta>
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Triokzz -Save Soil" />
            <meta property="og:description" content="Triokzz is a software development ,selling partner and product base company-designs, develops, and maintains software applications, frameworks, and tools" />
            <meta property="og:image" content={Logo} />
            <meta property="og:video" content="https://www.youtube.com/watch?v=A51nvgt99Cc" />
            <meta property="og:url" content="https://www.triokzz.com/savesoil" />
            <meta property="og:site_name" content="A service guarantee is one way a company can encourage customer complaints and Our focus is to become a troubleless provider in our network." />
        </Helmet>
        <form
            onSubmit={handleSubmit(onSubmit)}
        // method="POST" name="customerData" action="https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/ccavRequestHandler"
        >
            <div class="w-full flex flex-col items-center justify-center bg-teal-lightest font-sans">

                <div class="bg-white rounded shadow p-8 m-4 max-w-xs text-center ">
                    <div class="mb-4">
                        <img src="https://static.sadhguru.org/d/46272/1646819925-cp-banner.jpg" className="col-span-2 col-start-2 max-h-28 w-full object-contain sm:col-start-auto lg:col-span-1" alt="Save Soil Save Life" title="Save Soil Save Life" />
                        <h1>Namaskaram, Welcome to plant a tree campaign!</h1>
                    </div>
                    <div class="mb-8">
                        <p>Plant a tree so next generation can get air for free</p>
                    </div>
                    <div class="flex justify-center">
                        <button class="flex-no-shrink text-white py-2 px-4 rounded bg-teal hover:bg-teal-dark">Note: This Campaign now only for shanimandir tathawade area, Save Soil</button>
                    </div>
                </div>

            </div>


            <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
                <h2 className="text-xl font-medium">Earth Lover Details</h2>
                <p className="text-gray-400">Fill your details so we can reach to you accordingly</p>
                <div className="">
                    <label for="billing_name" className="mt-4 mb-2 block text-sm font-medium">Full Name</label>
                    <div className="relative">
                        <input type="text" id="billing_name" {...register("billing_name", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm uppercase shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="Full Name" />
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                            </svg>
                        </div>
                    </div>
                    <label for="billing_email" className="mt-4 mb-2 block text-sm font-medium">Email</label>
                    <div className="relative">
                        <input type="billing_email" id="billing_email" {...register("billing_email", { required: true })} value={"" || userstate && userstate.state && userstate.state.email} className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="email@gmail.com" />
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                            </svg>
                        </div>
                    </div>
                    <div className="relative">
                        <input type="text" id="delivery_name" {...register("delivery_name", { required: true })} value="Mission Green City" className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm uppercase shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="TRIOKZZ" />
                        {errors.delivery_name && <span style={{ color: "red" }}>please enter receiver name</span>}
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                            </svg>
                        </div>

                    </div>

                    {/* <label for="card-no" className="mt-4 mb-2 block text-sm font-medium">Card Details</label>
                    <div className="flex">
                        <div className="relative w-7/12 flex-shrink-0">
                            <input type="text" id="card-no" {...register("card-no")} className="w-full rounded-md border border-gray-200 px-2 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="xxxx-xxxx-xxxx-xxxx" />
                            <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                                <svg className="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                                    <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                                </svg>
                            </div>
                        </div>
                        <input type="text" {...register("credit-expiry")} className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="MM/YY" />
                        <input type="number" {...register("credit-cvc")} className="w-1/6 flex-shrink-0 rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="CVC" />
                    </div> */}
                    <label for="delivery_address" className="mt-4 mb-2 block text-sm font-medium">Detail Address</label>
                    <div className="flex grid grid-cols-1 lg:grid-cols-2  sm:flex-row">
                        <input type="text" {...register("delivery_zip", { required: true })} className="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-pink-500" placeholder="pincode" />


                    </div>
                    <div className="relative">
                        <select type="text" {...register("delivery_state", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500
                         invalid:border-pink-500 invalid:text-pink-600
                         focus:invalid:border-pink-500 focus:invalid:ring-pink-500">
                            {stateofcountry && stateofcountry[0].PostOffice && stateofcountry[0].PostOffice.map((stateof, i) => <option value={stateof.Circle}>{stateof.Circle}</option>)}
                        </select>
                        <select type="text" {...register("delivery_city", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500
                         invalid:border-pink-500 invalid:text-pink-600
                         focus:invalid:border-pink-500 focus:invalid:ring-pink-500">
                            {stateofcountry && stateofcountry[0].PostOffice && stateofcountry[0].PostOffice.map((stateof, i) => <option value={stateof.Name}>{stateof.Name}</option>)}
                        </select>
                    </div>
                    {/* <div className="relative">
                        <input type="text" {...register("delivery_state", { required: true })} className="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-pink-500" placeholder="delivery state" />
                        <input type="text" {...register("delivery_city", { required: true })} className="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-pink-500" placeholder="delivery city" />

                    </div> */}
                    <div className="relative">
                        <input type="text" id="delivery_address" {...register("delivery_address", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="flat no./house/socity/delivery Address" />
                        {errors.delivery_address && <span style={{ color: "red" }}>Pickup address is required</span>}
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <img className="h-4 w-4 object-contain" src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/800px-Flag_of_India.svg.png" alt="" />
                        </div>
                    </div>
                    <div className="relative">
                        <input type="text" id="billing-address" {...register("billing_address", { required: true })} className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" placeholder="Street Address/near by location" />
                        {errors.billing_address && <span style={{ color: "red" }}> Perfect address is required</span>}
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <img className="h-4 w-4 object-contain" src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/800px-Flag_of_India.svg.png" alt="" />
                        </div>
                    </div>
                    <div className=" grid grid-cols-1 lg:grid-cols-1  sm:flex-row">
                        <input type="text" name="delivery_tel" {...register("delivery_tel", { required: true, maxLength: 10, pattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/ })}
                            className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500  invalid:border-pink-500 invalid:text-pink-600
                     focus:invalid:border-pink-500 focus:invalid:ring-pink-500" placeholder="Mobile Number" />
                        {errors.delivery_tel && <span style={{ color: "red" }}>please enter your 10 digit mobile number</span>}
                    </div>
                    <label for="delivery_address" className="mt-4 mb-2 block text-sm font-medium">Suggestion</label>
                    <div className="flex grid grid-cols-1 lg:grid-cols-1  sm:flex-row">

                        <input type="text" {...register("merchant_param1")} placeholder="Tell us how we can improve positive vib around us..." className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-pink-500 focus:ring-pink-500" />
                    </div>
                    {/* total payment  */}

                    {/* 
                    <div className="mt-6 border-t border-b py-2">
                        <div className="flex items-center justify-between">
                            <p className="text-sm font-medium text-gray-900">Subtotal</p>
                            <p className="font-semibold text-gray-900">{subtotal}</p>
                        </div>
                        {subtotal < 1449 && <div className="flex items-center justify-between">
                            <p className="text-sm font-medium text-gray-900">Shipping</p>
                            <p className="font-semibold text-gray-900">00.00 Rs</p>
                        </div>}
                    </div> */}
                    {/* <div className="mt-6 flex items-center justify-between">
                        <p className="text-sm font-medium text-gray-900">Total</p>
                        <p className="text-2xl font-semibold text-gray-900">00</p>
                    </div> */}

                    <table width="40%" height="100" border='1' align="right">
                        {/* <tr>
                            <td><input type="hidden" name="redirect_url" {...register("redirect_url", { required: true })} value="https://api.triokzz.com/ccavResponseHandler" />
                            </td>
                        </tr>
                        <tr>
                            <td><input type="hidden" name="cancel_url" {...register("cancel_url", { required: true })} value="https://api.triokzz.com/ccavResponseHandler" />
                            </td>
                        </tr> */}
                        <tr disabled>
                            {/* <td>Merchant Id</td> */}
                            <td><input type="hidden"  {...register("merchant_id", { required: true })} id="merchant_id" value="8585658" /> </td>
                        </tr>
                        <tr>
                            {/* <td>Order Id</td> */}
                            <td><input type="hidden" {...register("order_id", { required: true })} value={((Math.random().toString(10).slice(2)).toString())} /></td>
                            {/* <td><input type="hidden" {...register("order_id", { required: true })} value={(userstate && userstate.state && userstate.state.jti && userstate.state.jti.split("").splice(0, 5).join("") + userstate && userstate.state && userstate.state.exp) || (session && session.apiKey && session.apiKey.split("").splice(0, 5).join("") + subtotal && subtotal.toString())} /></td> */}

                        </tr>
                        <tr>
                            {/* <td>Currency</td> */}
                            <td><input type="hidden" name="currency" {...register("currency", { required: true })} value="INR" /></td>
                        </tr>
                        <tr>
                            {/* <td>Amount</td> */}
                            <td><input type="hidden" {...register("amount", { required: true })} value="00" readOnly /></td>
                            {/* <td><input type="text" name="amount"  {...register("amount", { required: true })} value="1.00" /></td> */}
                        </tr>
                        <tr>
                            {/* <td>Language</td> */}
                            <td><input type="text" {...register("language", { required: true })} id="language" value="EN" /></td>
                        </tr>

                    </table>
                    <input type="submit" className="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white" value="Register Now" />
                </div>
                <div>
                </div>
            </div>
        </form>
    </div>
    )
}

export default Login