import React from 'react'
import {
    useParams, useNavigate
} from "react-router-dom";
import Logo from './../assist/triokzzlohgo.svg';
import { Helmet } from 'react-helmet-async';

const Privacy = () => {
    const navigate = useNavigate()
    return (
        <div class="bg-red-50">
            <Helmet>
                <title>privacy policy- better to understand our services</title>
                <meta name="description"
                    content="We aim to make the buying and receiving process for the customer as easy as possible.Similarly, our aim is to get product to customer in top condition">
                </meta>
                <meta name="author" content="Triokzz" />
                <meta name="application-name" content="Triokzz" />
                <meta name="robots" content="index, follow" />
                <meta name="robots" content="max-image-preview:large" />
                <meta name="google-site-verification" content="vwUAwcHi4pY-Lgm6a9JJyOwn1GLbkA7gO6fvQOy4FvU" />
                <meta name="keywords" content="Privacy policy, Triokzee, Triox, Triokx, return cost, Easy return policy"></meta>

                <link rel="canonical" href="https://www.triokzz.com/privacy" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Triokzz- Privacy Policy" />
                <meta property="og:description" content="We aim to make the buying and receiving process for the customer as easy as possible.Similarly, our aim is to get product to customer in top condition" />
                <meta property="og:image" content="https://www.triokzz.com/static/media/review3.30857420ac336e1c90b3.png" />
                <meta property="og:url" content="https://www.triokzz.com/privacy" />
                <meta property="og:site_name" content="Triokzz policy" />
            </Helmet>
            <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
                <div
                    className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div
                    className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>

                <div class="max-w-screen-xl mx-auto py-8 px-4 lg:py-16 lg:px-6">
                    <div class="text-center mb-10">
                        <h2 class="text-4xl tracking-tight font-bold text-primary-800"> Features</h2>
                    </div>

                    <div class="flex flex-col md:flex-row">

                        <div class="flex-1 flex flex-col sm:flex-row flex-wrap -mb-4 -mx-2">

                            <div class="w-full sm:w-1/2 mb-4 px-2 ">
                                <div class="h-full py-4 px-6 border border-green-500 border-t-0 border-l-0 rounded-br-xl">
                                    <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl capitalize">Privacy Policy</h1>
                                    <p className="mt-6 text-lg leading-8 text-gray-300">We aim to make the buying and receiving process for the customer as easy as possible.Similarly, our aim is to get product to customer in top condition
                                        and completely safe from any mis-happenings.
                                        Therefore,we require a minimum of 5-7 days to ship a product.Some products might require approx 10 days for shipping.The shipping detail will be mentioned under the product description section of the product.
                                        As a seller we cannot guarantee timely shipping in case of any natural disasters or events such as floods or road closures due to some social reasons.
                                        <span className="text-md font-bold tracking-tight text-white sm:text-md capitalize">Return Policy:</span>
                                        <ul>
                                            <li>Easy return requests <a href="/contact">link</a>.</li>
                                            <li>
                                                We accept returns for defective products only.</li>
                                            <li>Yes, We accept exchanges.</li>
                                            <li>Pre-paid shipping.</li>
                                            <li>Return Cost: 10% restocking fee for returns.</li>
                                            <li>60 day return window.</li>
                                            <li> We are very thankful if you will do video before opening a packet as a feedback and send it to us on triokzz0@gmail.com</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="max-w-screen-xl mx-auto py-8 px-4 lg:py-16 lg:px-6 bg-white">
                    <div class="text-center mb-10">
                        <h2 class="text-4xl tracking-tight font-bold text-primary-800"> Fassai Food Products Policy</h2>
                    </div>

                    <div class="flex flex-col md:flex-row">

                        <div class="flex-1 flex flex-col sm:flex-row flex-wrap -mb-4 -mx-2">
                            <div class="w-full sm:w-1/2 mb-4 px-2 ">
                                <div class="h-full py-4 px-6 border border-green-500 border-t-0 border-l-0 rounded-br-xl">
                                    <h3 class="text-2xl font-bold text-md mb-6">Check Expiry:</h3>
                                    <p class="text-sm">Our platform leverages user data and behavior to provide a highly personalized experience, with dynamic content and product recommendations that change in real-time.
                                        If you are buying fassai marked products, Make sure the expiry date before opening the products.
                                    </p>
                                </div>
                            </div>
                            <div class="w-full sm:w-1/2 mb-4 px-2 ">
                                <div class="h-full py-4 px-6 border border-green-500 border-t-0 border-l-0 rounded-br-xl">
                                    <h3 class="text-2xl font-bold text-md mb-6">Soft Services</h3>
                                    <p class="text-sm"> We do confirm the delivery product before delivered but, somehow if buyer get expired product the please do not open it.
                                        TRIOKZZ give you free return service.</p>
                                </div>
                            </div>

                            <div class="w-full sm:w-1/2 mb-4 px-2 ">
                                <div class="h-full py-4 px-6 border border-green-500 border-t-0 border-l-0 rounded-br-xl">
                                    <h3 class="text-2xl font-bold text-md mb-6">24/7 Customer Support</h3>
                                    <p class="text-sm"> IND.-based customer support team is available around the clock to answer any questions, resolve any issues, and provide helpful solutions. Whether it's through email, phone, or live chat, we're always here to support you.</p>
                                </div>
                            </div>

                            <div class="w-full sm:w-1/2 mb-4 px-2 ">
                                <div class="h-full py-4 px-6 border border-green-500 border-t-0 border-l-0 rounded-br-xl">
                                    <h3 class="text-2xl font-bold text-md mb-6">Secure Payment Processing</h3>
                                    <p class="text-sm">We use cutting-edge security measures to protect our customers' sensitive information and ensure the safety of all transactions made on our site.</p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">

                    </div>
                </div>
            </div>
            <footer class=" relative 
             inset-x-0 bottom-0 bg-gray-900 rounded-lg shadow dark:bg-gray-900 m-2">
                <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <a href="#" class="flex items-center mb-4 sm:mb-0">
                            <img src={Logo} class="h-12 mr-3" alt="shoppp Logo" />
                        </a>
                        <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-white">
                            <li class="mr-4 hover:underline md:mr-6 " onClick={() => navigate("/product/mens")}>
                                Home
                            </li>
                            <li class="mr-4 hover:underline md:mr-6 " onClick={() => navigate("/about")}>
                                About
                            </li>
                            <li class="mr-4 hover:underline md:mr-6" onClick={() => navigate("/privacy")}>
                                Privacy
                            </li>
                            <li class="mr-4 hover:underline md:mr-6 ">
                                <a href="#" class="hover:underline">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <div class="sm:flex sm:items-center sm:justify-between">
                        <a href="#" class="flex items-center mb-4 sm:mb-0 text-white">
                            Products
                        </a>
                        <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-white sm:mb-0 dark:text-white">
                            <li class="mr-4 hover:underline md:mr-6 " onClick={() => navigate("/product/mens")}>
                                Unisex Tshirt
                            </li>
                            <li class="mr-4 hover:underline md:mr-6 " onClick={() => navigate("/product/womens")}>
                                Plain Tshirt
                            </li>
                            <li class="mr-4 hover:underline md:mr-6" onClick={() => navigate("/product/travelling")}>
                                Travel Bag
                            </li>
                            <li class="mr-4 hover:underline md:mr-6" onClick={() => navigate("/product/printed")}>
                                Printed Mug
                            </li>
                            <li class="mr-4 hover:underline md:mr-6 ">
                                <a href="#" class="hover:underline">Contact</a>
                            </li>
                        </ul>
                    </div>
                    <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                    <span class="block text-sm text-white sm:text-center dark:text-gray-400">© 2023 develop by <a href="#" class="hover:underline">Triokzz</a>. All Rights Reserved.</span>
                </div>
            </footer>
        </div>
    )
}


export default Privacy