import React, { useEffect, useRef } from 'react';
import { PaperClipIcon } from '@heroicons/react/20/solid'
import {
    useLocation
} from "react-router-dom";
import html2canvas from "html2canvas";
import { Helmet } from 'react-helmet-async';
import { Buffer } from 'buffer';
import AWS from "aws-sdk";
import env from "react-dotenv";
import Logo from './../assist/triokzzlohgo.svg';

const DetailToCheckout = () => {
    const { state } = useLocation();
    console.log(state)
    const exportRef = useRef();


    const CaptureElement = async (element, sendmessage, products) => {
        const canvas = await html2canvas(element);
        console.log(canvas)
        const image = canvas.toDataURL("image/png", 1.0);
        // let base56 = new Buffer.from(image.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        const type = image.split(';')[0].split('/')[1];
        console.log(process.env.REACT_APP_BUCKET)
        AWS.config.update({
            Bucket: 'shopnowindia',
            region: process.env.REACT_APP_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRATE_ACCESS_KEY,
            s3Url: 's3://shopnowindia/billing/', /* optional */

        });
        const s3 = new AWS.S3({ params: { Bucket: 'shopnowindia' } });

        const data = {
            Key: Date.now() + '.jpg',
            Body: new Buffer.from(image.replace(/^data:image\/\w+;base64,/, ""), 'base64'),
            ContentEncoding: "base64",
            ContentType: "image/png",
        };

        var req = s3.upload(data, function (error, data) {
            if (error) {
                console.error(error)
            } else {
                console.log(data)
            }
        })
        req.on('send', function () {
            // abort request after 5 seconds
            setTimeout(function () {
                req.abort();
            }, 1000 * 5);
        });
        req.send(function (err, data) {
            console.log(err, data);
            let number = 9096324272
            // 8007880404
            let message = sendmessage
            let imageurl = data.Location.replaceAll("'", " ")
            message += `+ ${imageurl}`
            let url = `https://web.whatsapp.com/send?phone=${number}`;

            // Appending the message to the URL by encoding it
            url += `&text=${encodeURI(message)}&app_absent=0`;
            url += `&image=${imageurl}&app_absent=0`
            window.open(url);
        });
        // console.log(image)

        // let number = 9096324272
        // let message = sendmessage
        // // message += `<a href=${image.parseInt()}   ></a>`
        // let url = `https://web.whatsapp.com/send?phone=${number}`;

        // // Appending the message to the URL by encoding it
        // url += `&text=${encodeURI(message)}&app_absent=0`;
        // url += `&image=${image}&app_absent=0`
        // window.open(url);

    }


    const getWhatsappLinkmessage = (sendtonumber, sendmessage) => {

    }
    return (<div>
        <div ref={exportRef} className="p-10">
            <div className=" grid grid-cols-2 px-4 sm:px-0">
                <div className="ml-1 flex lg:ml-0">
                    {/* <a href="/"> */}
                    <span className="sr-only">shoptodecore</span>
                    <img
                        className="h-25 w-25"
                        src={Logo}
                        alt="shoptodecore"
                    />
                    {/* </a> */}
                </div>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p>
                {/* <h3 className="text-base font-semibold leading-7 text-gray-900">{state.product_for}</h3> */}

            </div>
            <div className="mt-6  border-t border-gray-100">
                <dl className="divide-y text-start divide-gray-100">
                    <div className="px-4  py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{state.product_for}</dd>
                        <dt className="text-sm font-medium leading-6 text-gray-900">Date</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{state.date}</dd>
                        <dt className="text-sm font-medium leading-6 text-gray-900">Order Number:</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{state.order_id}</dd>
                        <dt className="text-sm font-medium leading-6 text-gray-900">Tracking Number:</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{state.tracking_id}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Billing Address</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{state.billing_address}</dd>
                        <dt className="text-sm font-medium leading-6 text-gray-900">Street Address</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{state.street_address} ,{state.city},{state.state}</dd>
                    </div>
                    <table className="table-auto text-left text-sm font-light">
                        <thead className="border-b font-medium dark:border-neutral-500">
                            <tr>
                                <th scope="col" className="px-6 py-4">#</th>
                                <th scope="col" className="px-6 py-4">Brand</th>
                                <th scope="col" className="px-6 py-4">Color</th>
                                <th scope="col" className="px-6 py-4">Price</th>
                                <th scope="col" className="px-6 py-4">Quantity</th>
                                <th scope="col" className="px-6 py-4">Size</th>
                                <th scope="col" className="px-6 py-4">Type</th>
                                <th scope="col" className="px-6 py-4">id</th>
                                <th scope="col" className="px-6 py-4">Product Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.section.map((products, i) => {
                                return (
                                    <tr ref={exportRef} className="border-b dark:border-neutral-500"
                                        onClick={() => CaptureElement(exportRef.current, `*Full name*:${state.product_for},
                                                         *Billing Address*:${state.billing_address},
                                                         *Street Address* : ${state.street_address} ,
                                                         ${state.city},${state.state} ,
                                                         product -
                                                         *brand*: ${products.name} ,
                                                        *color*:${products.colors.name}`, products)}>
                                        <td className="whitespace-nowrap px-6 py-4 font-medium">{i}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{products.name}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{products.colors.name}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{products.price}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{products.count}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{products.sizes.name}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{products.type}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{products.id}</td>
                                        <td className="whitespace-nowrap px-6 py-4">{products.detail}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">               <div className="ml-1 flex lg:ml-0">
                            {/* <a href="/"> */}
                            <span className="sr-only">shoptodecore</span>
                            <img
                                className="h-25 w-25"
                                src={Logo}
                                alt="shoptodecore"
                            />
                            {/* </a> */}
                        </div></dt>
                        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                    Delivary charge :00.00
                                </li>
                                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                    ===
                                </li>
                            </ul>
                        </dd>
                    </div>
                </dl>
            </div>
            <button>Develer</button>
        </div>
    </div>)
}

export default DetailToCheckout;