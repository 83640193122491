import React from 'react'
import {
    useParams, useNavigate
} from "react-router-dom";
import Triologo from "./../assist/triokzzlohgo.svg"
import Triorgz from './../assist/Triorgz.png'

const NavigationBar = (props) => {
    const navigate = useNavigate()
    return (
        <nav class="border-b border-gray-200 bg-green-500 dark:bg-gray-800 dark:border-gray-700">
            <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-1">
                <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src={Triologo} class="h-12" alt="Flowbite Logo" onClick={() => navigate('/product/all')} />
                    {/* <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Triokzz</span> */}
                </a>
                <ul className="flex flex-wrap items-center justify-between  text-sm font-medium text-gray-900 sm:mb-0 dark:text-white">

                    <li className="mr-4 hover:underline md:mr-6 ">
                        <a href="/product/all"> Home</a>
                    </li>

                    <li className="mr-4 hover:underline md:mr-6 ">
                        <a href="/watchtv">Ott</a>
                    </li>
                    <li className="mr-4 hover:underline md:mr-6">
                        <a href="/privacy">
                            Privacy
                        </a>
                    </li>
                    <li className="mr-4 hover:underline md:mr-6 " onClick={() => navigate("/contact")}>
                        Contact
                    </li>
                </ul>
                {/* <div class="hidden w-full" id="navbar-hamburger">
                    <ul class="flex flex-col font-medium mt-4 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                        {props && props.Grocery_Menu && props.Grocery_Menu.map((val, i) => {
                            return (
                                <li key={val.key}>
                                    <a href="#" class="block py-2 px-3 text-white bg-blue-700 rounded dark:bg-blue-600" aria-current="page">{val.value}</a>
                                </li>
                            )
                        })}
                    </ul>
                </div> */}
            </div>
        </nav>
    )
}

export default NavigationBar