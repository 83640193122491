import React, { useEffect, useState } from 'react';
import Chatbotmessager from "react-simple-chatbot";

const steps = [
    {
        id: '1',
        message: 'welcome to triokzz ! My name is Ghanasham, and I’m here to assist you. What can I help you with today?”',
        trigger: '2'
    },
    {
        id: '2',
        message: 'may i know your name please?',
        trigger: '3'
    },
    {
        id: '3',
        user: true,
        trigger: 'Name'
    },
    {
        id: 'Name',
        message: "Hi {previousValue}, How can triokzz help you",
        trigger: 'waiting true'
    },
    {
        id: 'waiting true',
        user: true,
        trigger: 'help'
    },
    {
        id: 'help',
        message: "To assist you better, I can send you a step-by-step guide via email.Could you please mail me on triokzz0@gmail.com",
        trigger: 'waiting'
    },
    {
        id: 'waiting',
        user: true,
        trigger: '4'
    },
    {
        id: '4',
        message: "See you on mail",
        trigger: 'anything'
    },
    {
        id: 'anything',
        user: true,
        end: true
    }
]

const Chatbot = () => {
    return (
        <div>
            <Chatbotmessager
                headerTitle="TriokzzChatbot"
                speechSynthesis={{ enable: false, lang: 'en' }}
                //  recognitionEnable={true}
                steps={steps}
            />
        </div>
    )
}

export default Chatbot;