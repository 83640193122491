import axios from 'axios'
import { useNavigate } from 'react-router-dom';
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const ADDTOCARD_LIST = 'ADDTOCARD_LIST'
export const GET_LISTOFORDER = 'GET_LISTOFORDER'
export const GET_PRODUCTS_FORDECORE = 'GET_PRODUCTS_FORDECORE'
export const ADMIN_LOGIN = 'ADMIN_LOGIN'
export const GET_GROCERY_PRODUCTS = 'GET_GROCERY_PRODUCTS'
export const ADDTOCARD_GROCERY_LIST = 'ADDTOCARD_GROCERY_LIST'


export const getProducts = (navItem, setMenuItems) => {
    return async (dispatch, getState) => {
        let TrendingProducts = await axios.get("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/products")
            .then(r => r)
            .then(res => res.data)
            .catch(err => console.log(err))
        setMenuItems(TrendingProducts)
        let productaction = TrendingProducts && TrendingProducts.products.find((val, i) => {
            return val.product_for == navItem
        })
        dispatch({
            type: 'GET_PRODUCTS',
            payload: productaction
        });
    }
};

export const getGroceryProducts = (navItem) => {
    return async (dispatch, getState) => {
        let TrendingProducts = await axios.get("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/organic_products")
            .then(r => r)
            .then(res => res.data)
            .catch(err => console.log(err))

        let productaction = TrendingProducts && TrendingProducts.products && TrendingProducts.products.find((val, i) => {
            return val.product_for == navItem
        })
        dispatch({
            type: 'GET_GROCERY_PRODUCTS',
            payload: productaction
        });
    }
};

export const getProductsforDecore = (item) => {
    return async (dispatch, getState) => {
        let TrendingProducts = await axios.get("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/products")
            .then(r => r)
            .then(res => res.data)
            .catch(err => console.log(err))

        let productaction = TrendingProducts && TrendingProducts.products.find((val, i) => {
            return val.product_for == item
        })
        dispatch({
            type: 'GET_PRODUCTS_FORDECORE',
            payload: productaction
        });
    }
};

export const AddtocardList = (orderedList) => {
    return async (dispatch) => {
        dispatch({
            type: 'ADDTOCARD_LIST',
            payload: orderedList
        });
    }
}

export const AddtoGROCERYList = (orderedList) => {
    return async (dispatch) => {
        dispatch({
            type: 'ADDTOCARD_GROCERY_LIST',
            payload: orderedList
        });
    }
}

export const oncheckout = () => {
    return async (dispatch, getState) => {
        let TrendingProducts = await axios.get("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/ccavResponseHandler")
            .then(r => r)
            .then(res => res.data)
            .catch(err => console.log(err))
        console.log(TrendingProducts)
        let productaction = TrendingProducts && TrendingProducts.products.filter((val, i) => {
            return val.product_id == 1
        })

        dispatch({
            type: 'GET_LISTOFORDER',
            payload: productaction
        });
    }
}

export const OnLogin = (data) => {
    const navigate = useNavigate()
    return async (dispatch) => {
        if (data.email == "triokzz0@gmail.com" && data.password == "triokzz9800") {
            navigate('/checkout', { state: data })
        }
    }
}

// export default { getProducts }