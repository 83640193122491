import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { OnLogin } from './../Redux/action'
import { useForm } from "react-hook-form";
import axios from 'axios'
import { Buffer } from 'buffer';
import AWS from "aws-sdk";

const Sellerform = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [base64Image, setBase64Image] = useState([]);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    console.log("bas64arr", base64Image)
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };


    const handleFileChange = async (event) => {

        const file = event.target.files[0];

        if (!file) return;
        const reader = new FileReader();
        reader.onload = function (e) {
            const img = new Image();
            img.src = e.target.result;
            img.onload = function () {
                const canvas = document.getElementById('canvas');
                const ctx = canvas.getContext('2d');
                const maxWidth = 800; // Adjust as needed
                const scaleSize = maxWidth / img.width;
                canvas.width = maxWidth;
                canvas.height = img.height * scaleSize;

                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                let quality = 0.7; // Initial quality
                let compressedDataUrl = canvas.toDataURL('image/webp', quality);

                // Loop to reduce size to 90KB
                while (compressedDataUrl.length > 90 * 1024 && quality > 0.1) {
                    quality -= 0.05;
                    compressedDataUrl = canvas.toDataURL('image/webp', quality);
                }

                // Display or use the compressed image
                // console.log(compressedDataUrl);

                AWS.config.update({
                    Bucket: 'shopnowindia',
                    region: process.env.REACT_APP_REGION,
                    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
                    secretAccessKey: process.env.REACT_APP_SECRATE_ACCESS_KEY,
                    s3Url: 's3://shopnowindia/organic_product/', /* optional */

                });
                const s3 = new AWS.S3({ params: { Bucket: 'shopnowindia' } });

                const data = {
                    Key: Date.now() + '.webp',
                    Body: new Buffer.from(compressedDataUrl.replace(/^data:image\/\w+;base64,/, ""), 'base64'),
                    ContentEncoding: "base64",
                    ContentType: "image/webp",
                };

                var req = s3.upload(data, function (error, data) {
                    if (error) {
                        console.error(error)
                    } else {
                        console.log(data)
                    }
                })
                req.on('send', function () {
                    // abort request after 5 seconds
                    setTimeout(function () {
                        req.abort();
                    }, 1000);
                });

                req.send(function (err, data) {
                    // console.log(data)
                    let imageurl = data.Location.replaceAll("'", " ")

                    let Images = base64Image.concat({ id: event.target.id, value: imageurl })

                    let arr = [...base64Image, { id: event.target.id, value: compressedDataUrl }]

                    const filteredArr = Images.reduce((acc, current) => {
                        const x = acc.find(item => item.id === current.id);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, []);
                    // console.log(filteredArr)
                    setBase64Image(filteredArr);
                });

                // let Images = base64Image.concat({ id: event.target.id, value: compressedDataUrl })

                // let arr = [...base64Image, { id: event.target.id, value: compressedDataUrl }]

                // const filteredArr = Images.reduce((acc, current) => {
                //     const x = acc.find(item => item.id === current.id);
                //     if (!x) {
                //         return acc.concat([current]);
                //     } else {
                //         return acc;
                //     }
                // }, []);
                // console.log(filteredArr)
                // setBase64Image(filteredArr);

            }
        }

        const base64 = reader.readAsDataURL(file);

    };
    const onSubmit = (data) => {

        let formdata = {

            "quantity": 1,
            "color": data.color,
            "offer_prize": data.offer_prize,
            "product_detail": data.product_detail,
            "count": 1,
            "rating": 4.5,
            "type": data.type,
            "product_name": data.product_name,
            "url": base64Image[0].value,
            "detail_url": base64Image[1].value,
            "sizeguide_url": base64Image[2].value,
            "specified_image": base64Image[3].value,
            "real_prize": data.real_prize,
            "size": data.size,
            "id": data.id,
            "freez": false,
            "brand": data.brand,
            "seller_name": data.seller_name,
            "delivery_charge": 99,
            "uni_productId": data.offer_prize + parseInt(Math.random().toString(10).slice(10))
        }
        axios.post("https://kqej90wmli.execute-api.ap-south-1.amazonaws.com/dev/client_handler", formdata).then(r => {
            if (r) {
                alert("Data submitted successfully")
                window.location.reload()
            }
        }
        ).catch(e => alert("Something went wrong,Please try again"))
        // console.log("data", formdata)


        // dispatch(OnLogin(data))
    }
    return (
        <div className="mt-10 p-5 sm:mx-auto sm:w-full sm:max-w-sm">
            <nav class="flex items-center justify-between flex-wrap bg-teal-500 p-6">
                <div class="flex items-center flex-shrink-0 text-white mr-6">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                    </svg>
                    <span class="font-semibold text-xl tracking-tight">Welcome Triokzz Partner</span>

                </div>
            </nav>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
                <div>
                    <label for="brand" className="block text-sm font-medium leading-6 text-gray-900">Brand Name</label>
                    <div className="mt-2">
                        <input id="brand" name="brand" type="text"  {...register("brand", { required: true })} autoComplete="brand" placeholder="Brand Name" required className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div>
                    <label for="product_name" className="block text-sm font-medium leading-6 text-gray-900">Product Name</label>
                    <div className="mt-2">
                        <input
                            id="product_name"
                            name="product_name"
                            type="text"
                            {...register("product_name", { required: true })}
                            autoComplete="product_name"
                            placeholder="Product Name"
                            required
                            className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div>
                    <label for="seller_name" className="block text-sm font-medium leading-6 text-gray-900">Seller Name</label>
                    <div className="mt-2">
                        <input
                            id="seller_name"
                            name="seller_name"
                            type="text"
                            {...register("seller_name", { required: true })}
                            autoComplete="seller_name"
                            placeholder="ex., Jaivik mart / Aviksha Arts"
                            required
                            className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div>
                    <label for="type" className="block text-sm font-medium leading-6 text-gray-900">Product Type <span className="text-sm flex text-gray-500">ex., metal,Ceramic mug, seed, flakes</span></label>
                    <div className="mt-2">
                        <input id="type" name="type" type="text"  {...register("type", { required: true })} autoComplete="type" placeholder="Product Type" required className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div>
                    <label for="product_detail" className="block text-sm font-medium leading-6 text-gray-900">Product Detail</label>
                    <div className="mt-2">
                        <input id="product_detail" name="product_detail" type="text"  {...register("product_detail", { required: true })} autoComplete="product_detail" placeholder="Product Detail" required className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div>
                    <label for="size" className="block text-sm font-medium leading-6 text-gray-900">Size</label>
                    <div className="mt-2">
                        <input id="size" name="size" type="text"  {...register("size", { required: true })} autoComplete="size" placeholder="Size" required className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div>
                    <label for="color" className="block text-sm font-medium leading-6 text-gray-900">Color</label>
                    <div className="mt-2">
                        <input id="color" name="color" type="text"  {...register("color", { required: true })} autoComplete="color" placeholder="Color" required className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div>
                    <label for="real_prize" className="block text-sm font-medium leading-6 text-gray-900">Actual M.R.P.</label>
                    <div className="mt-2">
                        <input id="real_prize" name="real_prize" type="text"  {...register("real_prize", { required: true })} autoComplete="real_prize" placeholder="1400" required className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                <div>
                    <label for="offer_prize" className="block text-sm font-medium leading-6 text-gray-900">Offer Sales Price </label>
                    <div className="mt-2">
                        <input id="offer_prize" name="offer_prize" type="text"  {...register("offer_prize", { required: true })} autoComplete="offer_prize" placeholder="900" required className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>

                <div>
                    <label for="id" className="block text-sm font-medium leading-6 text-gray-900">Product Code</label>
                    <div className="mt-2">
                        <input id="id" name="id" type="text"  {...register("id", { required: true })} autoComplete="id" placeholder="BA-1240" required className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>

                <div>
                    <span className="text-sm flex text-gray-500">All 4 Images Is Mandatory</span>
                    <div className="flex items-center justify-between">
                        <label for="url" className="block text-sm font-medium leading-6 text-gray-900">Product Image </label>
                        <div className="text-sm">
                            <input id="url" name="url" type="file" accept="image/*"  {...register("url", { required: true })} onChange={handleFileChange} autoComplete="url" required className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>

                    </div>

                    <div className="flex items-center justify-between">
                        <label for="detail_url" className="block text-sm font-medium leading-6 text-gray-900">Second Image</label>
                        <div className="text-sm">
                            <input id="detail_url" name="detail_url" type="file" accept="image/*"   {...register("detail_url", { required: true })} onChange={handleFileChange} autoComplete="detail_url" required className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <label for="sizeguide_url" className="block text-sm font-medium leading-6 text-gray-900">Third Image</label>
                        <div className="text-sm">
                            <input id="sizeguide_url" name="sizeguide_url" type="file" accept="image/*"   {...register("sizeguide_url", { required: true })} onChange={handleFileChange} autoComplete="sizeguide_url" required className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <label for="specified_image" className="block text-sm font-medium leading-6 text-gray-900">Size Guide Image</label>
                        <div className="text-sm">
                            <input id="specified_image" name="specified_image" type="file" accept="image/*"   {...register("specified_image", { required: true })} onChange={handleFileChange} autoComplete="specified_image" required className="block w-full rounded-md border-0 py-1.5 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>
                    <div className="flex ">
                        {base64Image && base64Image.map((val, i) => <img src={val.value} key={i} alt="Uploaded" className="ml-1 w-16 h-16 object-cover" />)}
                    </div>
                </div>
                <canvas id="canvas" style={{ display: "none" }}></canvas>
                <div>
                    <button type="submit" className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 px-2  text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Upload</button>
                </div>
            </form>
        </div>
    )
}

export default Sellerform;