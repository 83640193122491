import React, { Fragment, useState, useMemo, useEffect, useCallback, useRef, memo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import Logo from './../assist/triokzzlohgo.svg';

const usePreviousValue = value => {
    const myref = useRef();
    useEffect(() => {
        myref.current = value;
    }, [value]);
    return myref.current;
};

function ShoppingCard({ openshopping, setOpenShopping, onRemove, onCount, onOpen }) {
    let ListOfOrdered = useSelector(state => state.allProducts.order)
    const [open, setOpen] = useState(openshopping)
    const [addtocards, setaddtocards] = useState(ListOfOrdered)
    const [quantity, setQuantity] = useState(1)
    const [removeditem, setRemoveditem] = useState([])
    const [subtotal, setSubTotal] = useState(0)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const prevValue = usePreviousValue(ListOfOrdered)
    // console.log(addtocards)
    useEffect(() => {
        // let storage = localStorage.getItem('addtocard')
        // console.log("storage", storage)
        setaddtocards(ListOfOrdered)
    }, [ListOfOrdered])

    // console.log("kjkk", ListOfOrdered)

    const close = () => {
        // setOpenShopping(false)
        setOpenShopping(!open)
        setOpen(!open)
    }


    useEffect(() => {
        let subtotals = ListOfOrdered && ListOfOrdered.reduce((acc, curr) => {
            let itemtotal = curr.price * (curr.count ? curr.count : 1)
            return acc += itemtotal
        }, 0)
        setSubTotal(subtotals)
    }, [quantity, removeditem, ListOfOrdered])


    return (
        <Transition.Root show={true} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={close}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <Helmet>
                        <title>Shipping Your Products with us🛒</title>
                        <meta name="description"
                            content="you tees, womens basic tee shirt, indoor decor, home decor items near me, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags">
                        </meta>
                        <meta name="robots" content="index, follow" />
                        <meta name="robots" content="max-image-preview:large" />
                        <meta name="google-site-verification" content="vwUAwcHi4pY-Lgm6a9JJyOwn1GLbkA7gO6fvQOy4FvU" />
                        <meta name="keywords" content="you tees, womens basic tee shirt, indoor decor, home decor items near me, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags"></meta>
                        <meta property="og:type" content="product" />
                        {/* <meta property="og:title" content="Triokzz - Things to buy online: Top 99 home products for bharat 🛒" /> */}
                        <meta property="og:description" content="you tees, womens basic tee shirt, indoor decor, home decor items near me, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags" />
                        <meta property="og:image" content="https://shopnowindia.s3.ap-south-1.amazonaws.com/Triokzz+Bags/Grey+colour/Grey+color+Travelling+bag_1.png" />
                        <meta property="og:image:height" content="1200" />
                        <meta property="og:image:width" content="630" />
                        <meta property="og:url" content="https://www.triokzz.com/product/all" />
                        <meta property="og:image:alt" content="Images T-shirt Mugs Travel Bags Mouse Pad" />
                        <meta property="og:site_name" content="Triokzz your shopping partner 🛒" />

                        {/* <!-- Google tag (gtag.js) --> */}
                        <script async="" src="https://www.googletagmanager.com/gtag/js?id=G-RWZEXW6VMP"></script>
                        <script>
                            {
                                `window.dataLayer = window.dataLayer || [];
                                function gtag() {
                                dataLayer.push(arguments);
                                }
                                gtag("js", new Date());

                                gtag("config", "G-RWZEXW6VMP");`
                            }
                        </script>

                        {/* <!-- Google tag (gtag.js) --> */}
                        <script async="" src="https://www.googletagmanager.com/gtag/js?id=AW-11415759693"></script>
                        <script>
                            {
                                `window.dataLayer = window.dataLayer || [];
                                function gtag() {
                                dataLayer.push(arguments);
                                }
                                gtag("js", new Date());

                                gtag("config", "AW-11415759693");`
                            }
                        </script>

                        {/* <!-- Event snippet for Begin checkout conversion page --> */}
                        <script>
                            {
                                `gtag("event", "conversion", {
                                send_to: "AW-11415759693/QtgvCJ6asZEZEM3WusMq",
                                });`
                            }
                        </script>
                    </Helmet >
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl">
                                        <div className="flex-1 overflow-y-auto px-1 py-6 sm:px-4">
                                            <div className="flex items-start justify-between">
                                                <div className="ml-1 flex lg:ml-0">

                                                    <span className="sr-only">shoptodecore</span>
                                                    <img
                                                        className="h-10 w-25"
                                                        src={Logo}
                                                        alt="Solid T-shirt,Plain T-Shirt,Buy Plain Solid T-shirts,printo mug, Bewakoofy items, home decor items, sculptures, inspiring, large luggage bags, popular duffle, touring bags, big size travel bags online, purse, wholesale mugs, custom cups and mugs, ceramic printed mugs, copper drinking mugs, cups for camping, purses for women, purse sale, branded bags for women, multi pocket bags"
                                                    />
                                                    {/* </a> */}
                                                </div>
                                                <Dialog.Title className="mt-1 text-lg font-medium text-gray-900">Shopping cart</Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                                                        onClick={() => close()}
                                                    >
                                                        <span className="absolute -inset-0.5" />
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="mt-8">
                                                <div className="flow-root">
                                                    <ul role="list" className="-my-6 divide-y divide-gray-200">
                                                        {addtocards ? addtocards.map((product, i) => (
                                                            <li key={product.id} className="flex py-6">
                                                                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200" onClick={() => {
                                                                    onOpen(product, i)
                                                                    // onRemove(product, i)
                                                                    // setRemoveditem(product)
                                                                    close()
                                                                }} >
                                                                    <img
                                                                        src={product.imageSrc}
                                                                        alt={product.imageAlt}
                                                                        className="h-full w-full object-cover object-center"
                                                                    />
                                                                </div>

                                                                <div className="ml-4 flex flex-1 flex-col">
                                                                    <div>
                                                                        <div className="flex justify-between text-base font-medium text-gray-900">
                                                                            <h3>
                                                                                <a href={product.href}>{product.name}</a>
                                                                            </h3>
                                                                            <p className="ml-4" >₹ {product.price} /-</p>
                                                                        </div>
                                                                        <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                                                                    </div>
                                                                    <div className="flex flex-1 items-end justify-between text-sm">
                                                                        {/* <p className="text-gray-500 font-semibold">Qty <span className="text-indigo-600">{product.count}:</span></p> */}
                                                                        <div class="flex custom-number-input h-10 w-32">
                                                                            <label for="custom-input-number" class="w-full text-gray-700 text-sm font-semibold">
                                                                                Select Quantity
                                                                            </label>
                                                                            <div class="flex flex-row h-5 w-20 rounded-lg relative bg-transparent mt-1">
                                                                                {/* <input type="button" value={"-"} onClick={() => {
                                                                                    if (quantity > 1) {
                                                                                        onCount(quantity, product.id, i)
                                                                                        setQuantity(quantity => quantity - 1)
                                                                                    }

                                                                                }} class="w-10 text-white bg-gray-900 button-minus" data-field="quantity" ></input> */}

                                                                                {/* <input defaultValue={product.count ? product.count : 1} type="number" class="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none" name="custom-input-number"
                                                                                    // value={quantity}
                                                                                    onChange={(e) => {
                                                                                       
                                                                                            onCount(e, product.id, i)
                                                                                            setQuantity(e)
                                                                                        

                                                                                    }}
                                                                                    min="1" max="5" placeholter="enter qty"></input> */}

                                                                                {/* <input type="button" value={"+"} onClick={() => {
                                                                                    if (quantity < 6) {
                                                                                        onCount(quantity, product.id, i)
                                                                                        setQuantity(quantity => quantity + 1)
                                                                                    }

                                                                                }} class="w-10 text-white  bg-gray-900  button-plus" data-field="quantity" ></input> */}
                                                                                <button id="dropdown-phone-button" data-dropdown-toggle="dropdown-phone" class="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600" type="button">

                                                                                    {product.count}
                                                                                </button>
                                                                                <select id="countries" onChange={(e) => {
                                                                                    onCount(e, product.id, i)
                                                                                    setQuantity(e)
                                                                                }} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                                                    <option defaultValue={product.count ? product.count : 1} >1</option>
                                                                                    <option value="2">2</option>
                                                                                    <option value="3">3</option>
                                                                                    <option value="4">4</option>
                                                                                    <option value="5">5</option>
                                                                                    <option value="6">6</option>
                                                                                    <option value="7">7</option>
                                                                                    <option value="8">8</option>
                                                                                    <option value="9">9</option>
                                                                                    <option value="10">10</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex">
                                                                            <button
                                                                                type="button"
                                                                                className="font-medium text-indigo-600 hover:text-indigo-500"
                                                                                onClick={() => {
                                                                                    onRemove(product, i)
                                                                                    setRemoveditem(product)
                                                                                }}
                                                                            >
                                                                                Remove
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )) : ""}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                                            <div className="flex justify-between text-base font-medium text-gray-900">
                                                <p>Subtotal <span>all taxes includes</span></p>
                                                <p>₹ {subtotal} /-</p>
                                            </div>
                                            <p className="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p>
                                            {ListOfOrdered && ListOfOrdered.length > 0 &&
                                                <div className="mt-6" onClick={() => navigate('/login')}>
                                                    <a
                                                        href="#"
                                                        className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                                                    >
                                                        Checkout
                                                    </a>
                                                </div>}
                                            <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                                                <p>
                                                    or
                                                    <button
                                                        type="button"
                                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                                        onClick={() => close()}
                                                    >
                                                        Delivery: within 2 to 5 days<br></br> Continue Shopping
                                                        <span aria-hidden="true"> &rarr;</span>
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export default memo(ShoppingCard)