import { GET_PRODUCTS, ADDTOCARD_LIST, GET_LISTOFORDER, GET_PRODUCTS_FORDECORE, GET_GROCERY_PRODUCTS, ADDTOCARD_GROCERY_LIST } from '../action'
var initialState = [
    {
        userName: "kunal",
        email: "triokzz0@gmail.com"
    }
];
const allProducts = (state = { initialState }, action) => {
    switch (action.type) {
        case GET_PRODUCTS:
            return {
                ...state,
                user: action.payload,
                loggedIn: true
            };
            break;
        case GET_GROCERY_PRODUCTS:
            return {
                ...state,
                grocery: action.payload,
                loggedIn: true
            };
            break;
        case GET_PRODUCTS_FORDECORE:
            return {
                ...state,
                decoreuser: action.payload,
                loggedIn: true
            };
            break;
        case ADDTOCARD_LIST:
            return {
                ...state,
                order: action.payload,
                loggedIn: true
            };
        case ADDTOCARD_GROCERY_LIST:
            return {
                ...state,
                order: action.payload,
                loggedIn: true
            };
        case GET_LISTOFORDER:
            return {
                ...state,
                listoforder: action.payload,
                loggedIn: false
            }
        default:
            return state;
    }
};

export default allProducts;