import React, { useState } from "react"

const Whatsapp = () => {
    const [whatsappnum, setWhatsappNum] = useState(null)
    const SendToWhatsapp = () => {
        let number = whatsappnum
        let myPdf = 'https://www.triokzz.com/product/mens'
        // let mupdfview = myPdf.replaceAll("'", " ")
        let message = `
Get 40% to 60% off on each an every product.
The color of any substance is not because of what it is, it is what it rejects or what it gives away or reflects.
Something looks red not because it is red,
but because it holds back everything that white light contains and reflects red.
Visit Now
 + ${myPdf}`
        // let imageurl = data.Location.replaceAll("'", " ")
        let imageurl = 'https://shopnowindia.s3.ap-south-1.amazonaws.com/menwomen_teeshirts_unisex/JI-T-100-104.png'
        let finalimageurl = imageurl.replaceAll("'", " ")
        message += `+ ${finalimageurl}`
        let url = `https://web.whatsapp.com/send?phone=${number}`;

        // Appending the message to the URL by encoding it
        url += `&text=${encodeURI(message)}&app_absent=0`;
        url += `&image=${finalimageurl}&app_absent=0`
        window.open(url);
    }
    return (<div className="p-20">
        <h1>hello</h1>
        <input type="number" value={whatsappnum} onChange={(e) => setWhatsappNum(e.target.value)} placeholder="enter whatsapp number" />
        <button type="button" onClick={() => SendToWhatsapp()}>Send</button>
    </div>)
}

export default Whatsapp;