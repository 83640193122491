import React from 'react'
import Chatbot from './../pages/chatbot'
import { Helmet } from 'react-helmet-async';
import Logo from './../assist/triokzzlohgo.svg';
import {
    useParams, useNavigate
} from "react-router-dom";

const ContactUs = () => {
    const navigate = useNavigate()
    return (<section class="bg-white py-1 lg:py-[50px] overflow-hidden relative z-10">
        <nav class="flex items-center justify-between flex-wrap bg-teal-500 p-6">
            <div class="flex items-center flex-shrink-0 text-white mr-6" onClick={() => navigate(`/product/all`)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                </svg>
                <span class="font-semibold text-xl tracking-tight">Triokzz</span>

            </div>
        </nav>
        <Helmet>
            <title>Contact Us- Triokzz is a software development, selling partner service and product base company.</title>
            <meta name="description"
                content="A service guarantee is one way a company can encourage customer complaints and Our focus is to become a troubleless provider in our network.To Designs, Develops, and Maintains Software Applications, Frameworks, and Tools .">
            </meta>
            <meta name="robots" content="index, follow" />
            <meta name="robots" content="max-image-preview:large" />
            <link rel="canonical" href="https://www.triokzz.com/contact" />
            <meta name="keywords" content="contact us page, ecommerce site, Home decor, Handicraft items,pink world of mens womens faith meets fashion,trends"></meta>
            <meta name="author" content="kunal khadke" />
            <meta name="application-name" content="Triokzz"></meta>
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Triokzz -Contact Us" />
            <meta property="og:description" content="Triokzz is a software development ,selling partner and product base company-designs, develops, and maintains software applications, frameworks, and tools" />
            <meta property="og:image" content={Logo} />
            <meta property="og:video" content="https://www.youtube.com/watch?v=A51nvgt99Cc" />
            <meta property="og:url" content="https://www.triokzz.com/contact" />
            <meta property="og:site_name" content="Triokzz" />
        </Helmet>
        <div class="flex items-center justify-center">
            <div class="mx-auto w-full ">
                <div class="container">
                    <div class="flex flex-wrap lg:justify-between -mx-4">
                        <div class="w-full lg:w-1/2 xl:w-6/12 px-4">
                            <div class="max-w-[570px] mb-12 lg:mb-0">
                                <h1 class="block mb-4 text-base text-primary font-semibold">
                                    Contact Us
                                </h1>
                                <h2
                                    class="
                  text-dark
                  mb-6
                  uppercase
                  font-bold
                  text-[32px]
                  sm:text-[40px]
                  lg:text-[36px]
                  xl:text-[40px]
                  "
                                >
                                    GET IN TOUCH WITH US
                                </h2>
                                <p><a href="mailto:triokzz0@gmail.com">Send Email:triokzz0@gmail.com</a></p>
                                <p>Contact No:- <a href="tel:+05890000111">02047153653</a></p>
                                <p class="text-base text-body-color leading-relaxed mb-9">
                                    A service guarantee is one way a company can encourage customer complaints and Our focus is to become a troubleless provider in our network.
                                </p>

                            </div>
                        </div>
                    </div>
                    <div id="chat-container" class="bottom-16 right-4 w-86">
                        <div class="bg-white shadow-md rounded-lg max-w-lg w-full">
                            <Chatbot id="chatbox" class="p-4 h-80 overflow-y-auto" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}
export default ContactUs