import React, { Fragment, useState, useMemo, useEffect, useCallback, useRef, memo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Logo from './../assist/triokzzlohgo.svg';
import Hnadcrafted from '../assist/handicrafted_item.jpg';
import toast, { Toaster } from 'react-hot-toast';

function OfferCard({ navItem }) {
    const [open, setOpen] = useState(navItem == "all" ? true : false)

    const close = () => {
        setOpen(false)
    }
    const copytoclipboard = () => {
        navigator.clipboard.writeText("TRIOKZZ11")
        toast('Copied to Clipboard !')
        setOpen(false)
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={close}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="flex h-full flex-col bg-white shadow-xl">
                                    <div className="flex-1 overflow-y-auto px-1 py-6 sm:px-4">
                                        <div className="flex items-start justify-between">
                                            <Dialog.Title className="text-lg font-medium text-blue-900">Big Bold Sale-EXCLUSIVE OFFER FOR YOU</Dialog.Title>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                                                    onClick={() => close()}
                                                >
                                                    <span className="absolute -inset-0.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="relative grid grid-cols-1 w-full">
                                            <div class="container mx-auto">

                                                <div class="bg-gradient-to-br from-purple-600 to-indigo-600 text-white text-center py-1 px-2 rounded-lg shadow-md relative">
                                                    <div className="confetti">
                                                        <div className="confetti-piece">
                                                        </div>
                                                        <div className="confetti-piece"></div>
                                                        <div className="confetti-piece"></div>
                                                        <div className="confetti-piece"></div>
                                                        <div className="confetti-piece"></div>
                                                        <div className="confetti-piece"></div>
                                                        <div className="confetti-piece"></div>
                                                        <div className="confetti-piece"></div>
                                                        <div className="confetti-piece"></div>
                                                        <div className="confetti-piece"></div>
                                                        <div className="confetti-piece"></div>
                                                        <div className="confetti-piece"></div>
                                                        <div className="confetti-piece"></div>
                                                    </div>
                                                    <img src={Logo} class="w-20 mx-auto mb-2 rounded-lg" />
                                                    <h3 class="text-2xl font-semibold mb-2"><a href="#">Save more with coupon</a><br /></h3>
                                                    <div class="flex items-center space-x-2 mb-6 ml-4" onClick={() => copytoclipboard()}>
                                                        <span id="cpnCode" class="border-dashed border text-white px-4 py-2 rounded-l">TRIOKZZ11</span>
                                                        <span id="cpnBtn" class="border border-white bg-white text-purple-600 px-4 py-2 rounded-r cursor-pointer">Copy Code</span>
                                                    </div>

                                                    <div class="w-12 h-12 bg-white rounded-full absolute top-1/2 transform -translate-y-1/2 left-0 -ml-6"></div>
                                                    <div class="w-12 h-12 bg-white rounded-full absolute top-1/2 transform -translate-y-1/2 right-0 -mr-6"></div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>

                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export default memo(OfferCard)